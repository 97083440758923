<template>
<div>
    <v-container class="container--fluid grid-list-md text-center">
        <h1>Welcome {{user}}</h1>
    </v-container>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex';

export default {
    name: 'Dashboard',
    computed: {
        ...mapGetters([
            'user',
            'name',
        ]),
    },
};
</script>
